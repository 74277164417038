<template>
  <div>
    <!--begin::Head-->
<!--    <div class="kt-login__head">-->
<!--      <span class="kt-login__signup-label">{{-->
<!--        $t("AUTH.GENERAL.NO_ACCOUNT")-->
<!--      }}</span-->
<!--      >&nbsp;&nbsp;-->
<!--      <router-link-->
<!--        class="kt-link kt-login__signup-link"-->
<!--        :to="{ name: 'register' }"-->
<!--      >-->
<!--        {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}-->
<!--      </router-link>-->
<!--    </div>-->
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Recupero login</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div role="alert" class="alert alert-info">
            <div class="alert-text">
              Inserire la propria email, riceverete un messaggio con un link per il reset della password.
            </div>
          </div>

          <div
            role="alert"
            v-bind:class="{ show: errors.length }"
            class="alert fade alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>

          <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
          >
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email richiesta
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <router-link :to="{ name: 'login', params: {  }}" class="kt-link kt-login__link-forgot">
              Torna al Login
            </router-link>
            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              Invia
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->

      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/common/api.service";
import Vue from "vue";

export default {
  mixins: [validationMixin],
  name: "recover",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        // password: "demo"
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        // email
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      const that = this;


      // dummy delay
      setTimeout(() => {
        // send login request
        // this.$store
        //   .dispatch(LOGIN, { email, password })
        //   // go to which page after successfully login
        //   .then(() => this.$router.push({ name: "dashboard" }));
        ApiService.post("auth/recover-password", { email: email })
                .then(({ data }) => {
                  Vue.customNotifySuccess('Recupero login','Riceverai il link per il ripristino via email');
                })
                .catch(({ response }) => {
                  Vue.customNotifyError('Errore','Verificare la mail inserita e riprovare');
                });

        submitButton.classList.remove(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return window.whitelabel.getAppBackground();
    }
  }
};
</script>
